@import "../../styles/variables";

.productContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.productImage {
    // width: 215px;
    // height: auto;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
}

.productName {
    font-family: "PolySans Median";
    width: 226px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    // line-height: 20px;
    display: flex;
    color: $dark-gray;
    margin-top: 7px;
    cursor: pointer;
    align-items: center;
}

.productPrice {
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    width: 226px;
    /* text-decoration-line: line-through; */
    margin-bottom: 20px;
    color: #0b1b5c;

    p {
        font-size: 14px !important;
    }
}

.productFocus {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 91px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: calc(100% - 15px);
    bottom: 23%;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.productMainImage {
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.productContainer:hover .productFocus {
    opacity: 1;
}

.eyeButton,
.addButton {
    display: flex;
    transform: translateY(40px);
    transition: all 0.2s ease-in-out;
    padding: 0.5rem;
}

.productContainer:hover .eyeButton,
.productContainer:hover .addButton {
    transform: translateY(0);
}

.eyeButton:hover button,
.addButton:hover button {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}

.componentQuantityInput input {
    min-width: 60px;
}

.componentQuantityInput,
.type {
    display: flex;
    align-items: center;
    border: 1px solid #d8d8d8;
    height: 90%;
    min-height: 50px;
    min-width: 50px;
}

.componentQuantityInput input {
    text-align: center;
    min-width: 32px;
    border: 0;
    width: 100%;
}

.productDetails {
    display: flex;
    flex-direction: column;
}

.imageSection {
    flex: 1 1 10%;
}

.rightSection {
    display: flex;
    flex-direction: column;
    flex: 2;
    margin: 0px 20px 30px 20px;
}

.inputSection {
    display: flex;
    margin: 20px 0px;
}

.productClaim {
    margin-top: 20px;
    font-family: "PolySans Neutral";
    font-weight: normal;
    color: #444444;

    @media (max-width: 768px) {
        word-break: break-all;
    }
}

.componentQuantityInput {
    flex: 2;
}

.addToCart {
    margin-left: 20px;
    flex: 8;
}

.imageContainer {
    position: relative
}

.bluredBackground {
    position: absolute;
    width: 226px;
    bottom: 0px;
    text-align: center;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: #ffffff34;
    padding: 14px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
}

/* @media screen and (min-width: 640px) {
} */
@media screen and (min-width: 960px) {
    .productDetails {
        flex-direction: row;
        min-height: 550px;
    }

    .rightSection {
        margin: 0px 50px 20px 50px;
    }

    .productMainImage {
        height: 100%;
    }
}

/* @media screen and (min-width: 1080px) {
} */