@import "./variables";

.btn {
    // width: 150px;
    min-width: 150px;
    height: 50px;
    background-color: $main-color;
    color: $white;
    border: none;
    padding: 0;
    text-align: center;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-family: "PolySans Median", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    inline-size: fit-content;
    border-radius: 10px;
    text-decoration: none;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    /* Safari */

    .btn-loader {
        position: absolute;
        left: 47%;
        top: 10%;
    }

    &:hover {
        transition: all 0.3s;
        opacity: 0.9;
    }

    &.btn-green {
        background: #26ea8f;

        &:disabled {
            background-color: #e7e8ea;
            color: #c1c0c0;
        }

        @media (max-width: 700px) {
            width: 100%;
        }
    }

    &.btn-blue {
        background-color: #0b1b5c;

        &:disabled {
            background-color: #e7e8ea;
            color: #c1c0c0;
        }

        @media (max-width: 700px) {
            width: 100%;
            margin-top: 10px;
        }
    }

    &.btn-red {
        background-color: #fb2424;

        @media (max-width: 700px) {
            width: 100% !important;
        }
    }

    &.btn-transparent {
        background-color: transparent;
        border: 1px solid #dbdfea;
        color: #11131f;
        justify-content: space-evenly;
    }

    &.btn-lt-red {
        background-color: #f15e50;
        color: #fff;
    }

    &.btn-icon {
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 0px 10px;
        color: black;

        i {
            background-size: cover;
        }
    }

    &.w-100 {
        width: 100% !important;

        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
}

.btn:after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s;
    box-shadow: 0 0 10px 20px rgba(0, 62, 142, 0.468);
}

.btn:active:after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    border-radius: 10px;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
}

.btn:active {
    top: 1px;
}

.loading {
    opacity: 0.9;
}