.snackbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: fixed;
    bottom: 20px;
    padding: 10px 16px;
    border-radius: 5px;
    z-index: 10;
    min-width: 300px;
    max-width: 550px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2),
        0 6px 10px 0 rgba(0, 0, 0, .14),
        0 1px 18px 0 rgba(0, 0, 0, .12);
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    right: 20px;

    &.show {
        opacity: 1;
        transform: translateX(0);
    }

    &.hide {
        opacity: 0;
        transform: translateX(100%);
    }

    p {
        color: #ffffff;
        font-size: 14px;
    }
}

.closeIcon {
    background-image: url("../../styles/assets/icons/closeIconWhite.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    height: 22px;
    float: right;
    cursor: pointer;
}