.breadCrumbs {
    margin-top: 20px;
    max-width: 240px;
    padding-left: 25px;
}

.unactive {
    font-size: 14px;
    color: #afb2c3;
    text-transform: capitalize;
}

.active {
    font-size: 14px;
    font-weight: 600;
    color: #11131f;
    text-transform: capitalize;
}
