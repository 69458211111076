.title {
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #11131f;
    @media (max-width: 768px) {
        font-size: 18px;
       }
}
.subtitle {
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #11131f;
    margin-top: 10px;
    @media (max-width: 768px) {
        font-size: 16px;
       }
   
}
.qrCode {
    text-align: center;
    padding: 40px;
}
.closeIcon {
    background-image: url("../../../styles/assets/icons/closed.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.container {
    display: flex;
    justify-content: space-between;
}
.img {
    border: 15px solid #f5f6fa;
    width: 200px;
}
