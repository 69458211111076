@import "../../styles/variables";

.closeButton {
    color: #a9abb4;
}

.closeButtonNoHeader {
    position: absolute;
    top: 25px;
    right: 20px;
}

.closeIcon {
    background-image: url("../../styles/assets/icons/closeIcon2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    height: 22px;
    float: right;
    cursor: pointer;
}

.smContent {
    position: relative;
    max-width: 360px;
    margin: auto;
    margin-top: 50px;
    box-shadow: 0 0.125rem 0.25rem RGB(0 0 0/8%);
    border: none;
    padding: 20px;
    background: #fff;
    max-height: calc(100vh - 30%);
    overflow: hidden;
    border-radius: 10px;
    overflow-y: auto;

}

.mdContent {
    position: absolute;
    width: 70%;
    right: auto;
    bottom: auto;
    transform: translate(20%, 10%);
    box-shadow: 0 0.125rem 0.25rem RGB(0 0 0 / 8%);
    border: none;
    padding: 0;
    background: $white;
    max-height: calc(100vh - 50px);
    // overflow: scroll;
}

.modalHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 960px) {
    .mdContent {
        width: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        box-shadow: 0 0.125rem 0.25rem RGB(0 0 0/8%);
        border: none;
        padding: 0;
        background: $white;
        max-height: calc(100vh - 150px);
        overflow: scroll;
    }

    .smContent {
        max-height: calc(100vh - 150px);
        max-width: 450px;
        padding: 20px;
    }
}

@media screen and (max-width:768px) {
    .smContent {
        overflow: scroll;
        margin-top: 30%;
    }
}