.container {
    @media (min-width: 1280px) {
        max-width: 1220px !important;
    }
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .mainContainer {
        border: 1px solid #dbdfea;
        border-radius: 10px;
        // min-height: 60vh;
        margin-bottom: 20px;
        display: flex;
        flex: 1 1 750px;
        margin-right: 20px;
        flex-direction: column;

        .wrap {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 20px;
            border-bottom: 1px solid #dbdfea;

            &:last-child {
                border: none;
            }

            .title {
                font-family: "PolySans Median";
                font-weight: 700;
                font-size: 20px;
                text-align: center;
            }

            .content {
                font-size: 14px;
            }
        }

        @media (max-width: 1430px) {
            margin-right: 0px;
        }
    }

    .sideContainer {
        display: flex;
        flex-direction: column;
        flex: 1 1 250px;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #dbdfea;
        border-radius: 10px;
        padding: 25px;
        height: 300px;

        .title {
            font-family: "PolySans Median";
            font-weight: 700;
            font-size: 24px;
            text-align: center;
            margin-bottom: 20px;
        }

        .content {
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 20px;
        }
    }
}

.productContainer {
    border: 1px solid #dbdfea;
    border-radius: 10px;
    height: 300px;
    padding: 20px;
    margin-right: 20px;
}

.icon {
    display: block;
    width: 50px;
    height: 50px;
    background: #ffffff;
    border: 1px solid transparent;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.blue {
        background: #014989;
    }

    &.gray {
        background: #f5f6fa;
    }

    &.disinfectIcon {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/disinfect-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 16px;
            height: 20px;
            margin: auto;
        }
    }

    &.info {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/info.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.play {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/play.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.whiteCheck {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/white-check.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.sun {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/sun.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.info {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/info.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.check {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/check.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.batteryCharging {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/battery-charging.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.phoneOff {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/phone-off.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.alertOctagon {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/alert-octagon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }

    &.clock {
        &::after {
            content: " ";
            background-image: url("../../styles/assets/icons/clock.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 18px;
            height: 18px;
            margin: auto;
        }
    }
}

.barcodeimg {
    background-image: url("../../styles/assets/icons/barcodeimage.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 100%;
    height: 100%;
}

.vitaminD {
    background-image: url("../../styles/assets/vitaminD.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 100px;
    height: 100px;
}

.background {
    background: rgba(17, 19, 31, 0.8);
    backdrop-filter: blur(8px);
    min-height: 525px;
}

.title {
    color: #ffffff;
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding-top: 50px;
}

.subtitle {
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    /* or 167% */

    text-align: center;

    color: #ffffff;
    padding-top: 10px;
    //   padding-left: 200px;
    //   padding-right: 200px;
}

.buttonsText {
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    //   line-height: 32px;
    /* identical to box height, or 178% */

    text-align: center;
    text-transform: uppercase;

    /* White */

    color: #ffffff;
}

.barcodeText {
    font-family: "PolySans Median";
    font-style: normal;
    //   font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 131% */

    text-align: center;

    /* White */

    color: #ffffff;
    margin-top: 15px;
}

.pictureContainer {
    text-align: -webkit-center;
}

.barcodeContainer {
    text-align: -webkit-center;
}

.activateKitButton {
    width: 100%;
    max-width: 400px;
    min-width: 180px !important;
    height: 50px;
    border-radius: 10px;

    @media (max-width: 768px) {
        width: 95%;
        align-self: center;
        margin-top: 20px;
        margin-left: 0px;
    }
}

.barcodeButton {
    margin-top: -40px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #0B1B5C;
    cursor: pointer;
}