@import "../../styles/variables";

.ecocareLogo {
    margin-bottom: 20px;
}

.wrapper {
    padding: 4% 50px;
    padding-bottom: 25px;
    max-width: 1500px;
    margin: auto;
}

a:hover {
    text-decoration: underline;
    color: #ffffff;
}

.iconStyle {
    width: 24px;
    height: 20px;
    color: $white;
    margin-right: 10px;
    cursor: pointer;
}

.firstSection {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #1f1f1f;
    margin: 0px 0px 60px 0px;

    @media screen and (max-width: 640px) {
        text-align: center;
    }
}

.textContact {
    font-size: 12px;
    display: flex;
    color: white;
    align-items: center;
    gap: 5px;
}

.icon {
    background-image: url("../../styles/assets/icons/checkSmall.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 18px;
    height: 19px;
    // margin-top: 15px;
    margin-right: 5px;
    text-align: left;

}

.contactIcon {
    background-image: url("../../styles/assets/icons/message.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 18px;
    height: 19px;
    // margin-top: 15px;
    margin-right: 5px;
    text-align: left;
}

.visa {
    background-image: url("../../styles/assets/icons/visa.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.amex {
    background-image: url("../../styles/assets/icons/amex.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.card {
    background-image: url("../../styles/assets/icons/card.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.giroPay {
    background-image: url("../../styles/assets/icons/giropay.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}


.pp {
    background-image: url("../../styles/assets/icons/pp.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.klarna {
    background-image: url("../../styles/assets/icons/klarna.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 64px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.payIcon {

    max-width: 300px;
    display: flex;

}

.gebrauchsanleitung {
    background-image: url("../../styles/assets/icons/gebrauchsanleitung.svg");
    width: 142px;
    height: 32px;
    background-size: cover;
    background-repeat: no-repeat;

}

.appStore {
    background-image: url("../../styles/assets/icons/appStore.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 135px;
    height: 40px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.googlePlay {
    background-image: url("../../styles/assets/icons/googlePlay.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 135px;
    height: 40px;
    margin-top: 16px;
    margin-right: 25px;
    text-align: left;
}

.videoIcon {
    background-image: url("../../styles/assets/icons/videoIcon.svg");
    background-repeat: no-repeat;
    width: 142px;
    height: 32px;
    margin-right: 10px;
    background-size: cover;
}

.klarnaTwo {
    background-image: url("../../styles/assets/icons/klarnaTwo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 205px;
    height: 41px;
    margin-top: 16px;
    margin-right: 5px;
    text-align: left;
}

.phoneIcon {
    background-image: url("../../styles/assets/icons/phoneOld.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 18px;
    height: 19px;
    // margin-top: 15px;
    margin-right: 5px;
    text-align: left;
}

.locationIcon {
    background-image: url("../../styles/assets/icons/shape.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 18px;
    height: 19px;
    // margin-top: 30px;
    margin-right: 5px;
    text-align: left;
}

.title {
    font-style: normal;
    font-weight: 600;
    padding-right: 10px;
    padding-bottom: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: white;
}

.gebrauchsanweisung {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: white;
    padding-top: 30px;
    padding-bottom: 10px;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
    color: white;
    padding-top: 7px;
}

.link {
    cursor: pointer;
    margin: 3px 0px;
    font-size: 14px;

    &:hover {
        text-decoration: underline;
    }
}

.links {
    font-weight: 400;
    font-size: 16.0625px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
}

.divider {
    border: 0.5px solid #1f1f1f;
    margin: 10px 00px;
    opacity: 0.3;
}

.secondSection {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding-bottom: 30px;

    @media (max-width: 950px) {
        flex-direction: column;
        gap: 35px;

        .inputField {
            width: 70%;
        }

        .buttonContainer {
            width: 40% !important;
            margin-top: 20px;
        }
    }

    .buttonContainer {
        padding-top: 10px;
        width: 50% !important;

        button {
            background: none;
            outline: none;
            border: none;
            justify-content: flex-start;
            color: #26ea8f;
            font-size: 14px;
        }
    }

    .inputField {
        margin-right: 20px;
        color: white;

        input {
            border: none;
            background-color: transparent;
            outline: none;
            color: white;
            font-size: 18px;
            width: 100%;
            font-size: 14px;
        }
    }
}

.thirdSection {
    display: flex;
}

.arrowIcon {
    background-image: url("../../styles/assets/icons/arrowRight.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    height: 15px;
}

.socialMediaIcons {
    padding-top: 20px;
    display: flex;
    padding-left: 5px;
    gap: 5px;
}

.subscribeButton {
    width: 119px;
    display: flex;
    height: 45px;
    border: 0px;
    background: var(--Orange, #FC5E4D);
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    align-items: center;
    padding-left: 10px;
    font-size: 12px;
    color: #0B1B5C;
}

.subscribeButton p {
    font-size: 12px;
    color: #0B1B5C;

}


.subscribeInput {
    margin-top: 10px;
    display: flex;
}

.rightIcon {
    background-image: url("../../styles/assets/icons/arrowRightSmall.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.inputContainer {
    width: 137px;
    height: 43px;
    border-top-left-radius: 9999px;
    border: 0px;
    border-bottom-left-radius: 9999px;
    padding-left: 10px;
    font-size: 12px;
    background: var(--LightBlue, #DBEDFB);
    color: #0B1B5C;
    outline: none;
}

.inputContainer::placeholder {
    color: #0B1B5C;
}

.linkdinIcon {
    background-image: url("../../styles/assets/icons/inIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    margin-left: 10px;
    margin-right: 10px;
    height: 20px;
    cursor: pointer;
}

.xIcon {
    background-image: url("../../styles/assets/icons/xIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.instagramIcon {
    background-image: url("../../styles/assets/icons/instagram.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.inputBox {
    position: relative;
    width: 300px;
    height: 30px;
    border-bottom: 1px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.infoSection {
    display: flex;
    flex-direction: column;
    color: white;
}

.addressSection {
    display: flex;
    flex-direction: column;
    margin-right: 50px;


    @media screen and (max-width: 640px) {
        margin-right: 0px;
        flex-wrap: wrap-reverse;
    }
}

.newsletterTitle {
    color: var(--Light, #FFF);
    font-family: PolySans;
    font-size: 15px;
    font-style: italic;
    font-weight: 700;
    line-height: 20px;

}

.thirdSection {
    display: flex;
    justify-content: space-between;
}

.lastSection {
    justify-content: space-between;
    display: flex;
    text-align: center;
    background-color: #071345;
    padding: 10px 50px;
}

.lastSection p {
    color: white;
    font-size: 12px;
}

.point {
    border: 3px solid #fff;
    opacity: 0.5;
    border-radius: 16px;
    height: fit-content;
    align-self: center;
    margin-right: 5px;
    margin-top: 12px;
}


@media screen and (max-width: 640px) {
    .firstSection {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .infoSection {
        flex-direction: column;
    }

    .title {
        color: white;
        font-size: 18px;
        line-height: 20px;
        padding-bottom: 5px;
    }

    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .thirdSection {
        flex-direction: column;
        margin-top: 30px;
    }

    .links {
        margin-top: 12px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        opacity: 0.5;
        margin-right: 6px !important;
    }

    .ecocareLogo {
        display: block;
        width: 200px;
        margin: 15px auto;
    }

    .divider {
        margin: 0px;
    }
}

@media screen and (max-width: 960px) {
    .lastSection {
        flex-direction: column;
    }

    .icons {
        margin: 20px 0px;
    }


}

@media screen and (max-width: 1280px) {
    .links {
        margin-right: 10px;
        word-break: break-word;
    }

    .secondSection {
        width: 100%;
        justify-content: space-between;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        border: none;
        padding: 4% 20px;
    }

    .description {
        padding: 3px;
    }
}