@import "../../../styles/variables";

.categories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
}

.categoriesTitle {
    color: #11131F;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 15px;
}

.categoryChild {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 20px;
    cursor: pointer;
}

.mainCategories {
    display: flex;
    flex-direction: column;
}

.nonActiveCategory {
    font-weight: 400;
}

.activeCategory {
    color: #090d64;
}

.subCategories {
    opacity: 1;
    transition: all 0.3s;

    &.deActive {
        opacity: 0;
        display: none;
        transition: all 0.3s;
    }
}

.filterItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .itemWrapper {
        line-height: 24px;
        font-size: 18px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &::before {
            content: url("../../../styles/assets/icons/white-check2.svg");
            height: 18px;
            width: 18px;
            border: #888888 1px solid;
            border-radius: 3px;
            background-color: #202359;
            margin-right: 8px;
            transition: all 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.deActivate {
            &::before {
                background-color: transparent;
                content: "";
            }
        }
    }


    .itemsTag {
        background: #f7f9fb;
        border-radius: 4px;
        color: #1f1f1f;
        font-size: 14px;
        line-height: 22px;
        width: 27px;
        height: 25px;
        text-align: -webkit-center;

    }
}

@media screen and (min-width: 960px) {
    .searchInput {
        border: 1px solid #d5d5d5;
        width: unset;
        border-radius: 10px;

        input {
            border: none;
            width: unset;
        }

        .searchIcon {
            background-image: url("../../../styles/assets/icons/search.svg");
            background-repeat: no-repeat;
            background-size: cover;
            display: block;
            width: 16px;
            height: 16px;

            position: absolute;
            right: 10px;
            top: 10px;
            height: 15px;
        }
    }
}