@import "../../styles/variables";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
    flex-direction: column;
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #202359;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 0.5s linear infinite;
    /* Safari */
    animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadingText {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: $main-color;
    cursor: pointer;
    align-items: center;
}
