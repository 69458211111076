@import "/src/styles/variables";

.cartItemWeb {
    background: #fff;
    padding: 20px 25px;
    // margin-bottom: 10px;
    border-bottom: 1px solid #E3E3E3;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .content {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            margin-left: 10px;
            max-width: 210px;
        }

        .price {
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            margin-left: 10px;
            color: #515d90;

            &.redAlert {
                color: #912424;
                text-decoration-line: line-through;
            }
        }

        img {
            height: 80px;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    .quantityWidget {
        display: flex;
        background-color: #fff;
        border: 1px solid #dddddd;
        border-radius: 4px;
        min-width: 20px;
        color: #11131f;
        margin-right: 10px;
        width: fit-content;
        margin-left: 10px;

        &.disableActions {
            pointer-events: none;
            opacity: 0.5;
        }

        .widget {
            padding: 5px;
            min-width: 20px;
            text-align: center;

            &:last-child {
                border-right: none;
            }
        }
    }

    .actions {
        display: flex;
        margin: 10px 0px;

    }

    .bottomIcons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div:first-child {
            padding-left: 15px;
        }

        .editButton button {
            min-width: 100px;
            border-radius: 5px;
        }
    }

    .mobileInfoWrapper {
        display: flex;
        width: 100%;
        margin-top: 20px;
        font-size: 16px;

        .locationAndDateInfo {
            width: 100%;

            div:first-child {
                margin-bottom: 5px;
            }

            .infoContent .wrap {
                display: flex;
                align-items: center;
                padding: 10px 10px 10px 20px;
                background-color: #fff;
                min-height: 50px;

                .title {
                    font-weight: bold;
                    font-size: 16px;
                }

                .subTitle {
                    color: $lt-gray-text;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
    }

    .infoWrapper {
        background: #f5f6fa;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
        }

        .borderRight {
            border-right: 1px solid #e1eaf1;
        }

        .subTitle {
            font-weight: 600;
            font-size: 12px;
            color: #a1a1a1;
            line-height: 16px;
        }

        .wrap {
            display: flex;
            align-items: center;
            margin: 0px 10px;
        }
    }

    .calendar-icon {
        background-image: url("../../styles/assets/icons/Calendar-black.svg");
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 17px;
        height: 21px;
    }

    .location-icon {
        background-image: url("../../styles/assets/icons/location-black.svg");
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 19px;
        height: 21px;
    }

    .tc {
        display: flex;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #3c3b3b;
        padding: 15px 0px;
        transition: all 0.5s;

        .unBooked {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: $lt-blue;
            padding: 10px;
            transition: all 0.5s;
        }

        .booked {
            transition: all 0.5s;
            width: 100%;
            display: flex;
        }

        .mr2 {
            margin-right: 2rem;
        }

        .mr1 {
            margin-right: 1rem;
        }

        img {
            height: 89px;
            width: 65px;
            border-radius: 5px;
            object-fit: cover;
        }

        .btnBookAppointment {
            font-size: 12px;
            font-weight: 700;
            padding: 10px 20px;
            border: 1px solid #a9abb4;
            border-radius: 5px;
            color: #a9abb4;
            transition: all 0.3s;

            &:hover {
                transition: all 0.3s;
                opacity: 0.7;
            }
        }

        .editBookApoointment {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px !important;
            font-weight: 400;
            color: #3c3b3b;

            button {
                content: "";
                background-image: url("../../styles/assets/icons/edit.svg");
                background-repeat: no-repeat;
                background-size: cover;
                display: block;
                width: 18px;
                height: 18px;
                margin-left: 5px;
            }

            p {
                font-size: 12px;
            }

            .gray {
                color: #a3a3a3;
            }
        }

        .btnDelete {
            color: #a3a3a3;
            font-weight: 400;
        }

        .grayText {
            color: #a3a3a3;
        }

        .quantityWidget {
            display: flex;
            border: solid 1px #a3a3a3;
            min-width: 20px;
            color: #a3a3a3;

            .widget {
                padding: 5px;
                min-width: 20px;
                border-right: solid 1px #a3a3a3;
                text-align: center;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    .col {
        width: 13%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:last-child {
            justify-content: right;
        }

        &.colPro {
            justify-content: left;
            width: 60%;
        }
    }

    .quanityWrap {
        color: #a3a3a3;
        padding: 3px 20px;
        border: 1px solid #a3a3a3;
    }
}

.cartItemMobile {
    background: #ffffff;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    max-height: 100px;
    max-width: 332px;
    min-width: 332px;
    display: flex;
    margin-bottom: 20px;

    img {
        width: 100px;
        height: 100px;
        border-radius: 14px 0px 0px 14px;
    }

    .contentWrap {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .wrap {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1px;
                color: #2e3230;
            }

            .subTitle {
                font-weight: 400;
                font-size: 12px;
                color: #878b8e;
            }

            .price {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 1px;
                color: #014989;
            }
        }

        .quanityWrap {
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 5px 15px;
            max-height: 20px;
            border: 1px solid #a3a3a35c;
            color: #a3a3a3;
            border-radius: 10px;
        }

        .actionsWrap {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                .calendar-icon {
                    background-image: url("../../styles/assets/icons/Calendar-black.svg");
                    background-repeat: no-repeat;
                    background-size: cover;
                    display: block;
                    width: 17px;
                    height: 21px;
                }
            }

            .quantityWidget {
                display: flex;
                justify-content: center;
                align-items: center;

                .widget {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    background: #f5f5f5;
                    text-align: center;
                    color: #878b8e;
                }

                p {
                    margin: 0px 8px;
                }
            }
        }
    }
}

.cartSidebarRemoveItem {
    margin-right: 10px;
}

.removeItemIcon {
    content: "";
    background-image: url("../../styles/assets/icons/close.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 10px;
    height: 10px;
}

.cartPopup {
    .mt1 {
        margin-top: 1rem;
    }

    .mr1 {
        margin-right: 1rem;
    }

    .bold {
        line-height: 30px;
        font-weight: 600 !important;
    }

    .gray {
        color: #a3a3a3;
    }

    .title {
        font-family: "PolySans Neutral";
        font-size: 22px;
        font-weight: 700;
    }

    .content {
        margin-top: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #a3a3a3;
    }

    .btn {
        margin-top: 20px;
        border-radius: 12px;
        width: 100%;
        font-size: 20px;
    }
}