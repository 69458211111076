.userProfileWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
        line-height: 22px;
        color: #11131f;
    }

    .secondaryText {
        font-size: 12px;
        color: #8094ae;
        line-height: 20px;
    }
}

.userProfileMenuItem {
    font-size: 14px;
    display: flex;
    background-color: #f5f6fa;
    padding: 15px 10px;
    align-items: center;

    .icon {
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        line-height: 20px;
    }
}

.signOutMenuIcon {
    font-size: 14px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.wrapper {
    display: flex;
    justify-content: center;
    overflow: auto;
}

.languagesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    align-self: center;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    padding: 0px 10px;
    cursor: pointer;
    margin: 0px 5px;
}

.languageIcon {
    background-image: url("../../styles/assets/icons/globe.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 16px;
    height: 16px;
}

.badge {

    @media (max-width: 768px) {
        margin-right: 12px;
        margin-top: 5px;
    }
}

.languageTitle {
    font-weight: 600;
    font-size: 16px;
    color: #11131f;
    margin-left: 5px;
    line-height: 16px;
}

.enDropDown {
    // position: absolute;
    background-color: #fff;
    margin-top: 120px;
    margin-left: -10px;
    display: flex;
    align-items: center;
    height: 36px;
    align-self: center;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    padding: 0px 10px;
    cursor: pointer;
}

.deDropDown {
    // position: absolute;
    background-color: #fff;
    margin-top: 2px;
    margin-left: -10px;
    display: flex;
    align-items: center;
    height: 36px;
    align-self: center;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    padding: 0px 10px;
    cursor: pointer;
}

.sidebarLanguageBtn {
    width: 100px;
    padding-left: 7px;

    @media (min-width: 1200px) {
        display: none;
    }

    &.dashboard {
        display: block;

        @media (min-width: 1200px) {
            display: none;
        }
    }
}

.sidebarWrapperDashboard {
    width: 100%;
    display: block;

    @media (min-width: 1200px) {
        display: none;
    }
}

.contentWrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 1.2;
    font-size: 15px;
}

.link {
    color: #ffffffba;
    cursor: pointer;
    width: fit-content;
    text-decoration: underline !important;

    &:hover {
        color: #ffffffba;
        text-decoration: none !important;
    }
}

.mainNavBar {
    display: flex;
    max-width: 1280px;
    width: 100%;
    // @media (max-width: 900px) {
    //     display: none;
    // }

    .container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }

    .toggleWrapper {
        max-width: 300px;
    }

    .productCategoriess {
        display: flex;
        gap: 23px;
    }
}

.searchInput {
    display: flex;
    position: relative;
    margin-left: 0;
    max-width: 800px;
    min-width: 220px;
    width: 100%;
    margin-right: 20px;

    @media (min-width:900px) and (max-width : 1200px) {
        margin-right: 10px;
    }
}

.searchInput input {
    width: 100%;
    border: 1px solid #d4d4d4;
    padding: 13px;
    border-radius: 8px;
    outline: none;
}

.searchInput input:focus-visible {
    outline: none;
}

.searchIcon {
    background-image: url("../../styles/assets/icons/search.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 16px;
    position: absolute;
    right: 13px;
    top: 13px;
    height: 15px;
}

.button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        width: 95%;
        align-self: center;
    }
}

.ecocareLogoSvg {
    background-image: url("../../styles/assets/new_ecocare_logo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 166px;
    height: 33px;
    cursor: pointer;
}

.listButtonWrapper {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
}

.discountBanner {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    width: 100%;
    background-color: orangered;
    font-weight: 600;
    padding: 7px 0px;

    span {
        color: yellow;
        margin: 0px 7px;
    }

    @media (max-width: 768px) {
        font-size: 10px;
        font-weight: 400;

        span {
            font-size: 10px;
            font-weight: 400;
        }
    }
}