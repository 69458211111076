$main-color: #202359;
$second-color: #00557d;
$darker-blue-text: #076179;
$gray-text: #333333;
$background-overlay: #00000099;
$error: #bd0505;
$lt-gray-text: #999999;
$disabled-button: #999999;
$white: #fff;
$dark: #000;
$light-background: #f8f8f8;
$dark-gray: #444444;
$gray-border: #f2f2f2;
$cyan-blue: #8094ae;
$primary-blue: #014989;
$primary-blue-second: #202359;
$gray-border-select: #e2e2e2;
$lt-blue: #f5f6fa;
$label: #777777;
