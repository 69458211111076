.pageHeaderWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    .titleWrapp {
        display: flex;
        flex-direction: column;
        align-items: self-start;
        width: 100%;
    }

    @media (max-width: 720px) {
        h1 {
            font-size: 22px !important;
            line-height: 40px !important;
            padding: 15px;
            color: #0b1b5c;
        }

        .titleWrapp {
            align-items: center;
        }

        margin-bottom: 0px;
    }

    &.noBackBtn {
        margin-left: 5px;
        flex-direction: row !important;
    }

    .webTitles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .mobileTitles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dbdfea;

        button {
            padding-right: 15px;

            @media (max-width: 768px) {
                margin-right: 5px;
            }
        }
    }

    .mobileSubtitles {
        // padding: 40px 40px 40px 10px;
        margin-left: 10px;

        span,
        p {
            width: 100%;

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        align-items: end;
        margin-top: 0px;

        @media (max-width: 680px) {
            align-items: center;

            &.noBackBtn {
                margin-left: 0px;
            }

            div h1 {
                margin: 0px;
                font-size: 2rem;
                padding-left: 10px;
            }
        }
    }
}

.mobileWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dbdfea;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid #dbdfea;

    .mobileTitle {
        color: #0b1b5c;
        font-weight: 700;
        font-size: 20px;
    }
}