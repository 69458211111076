@import "../../../styles/variables";

.navbarWrapper {
    width: 100%;
    height: 78px;
    background: $white;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 0.5px solid rgba(18, 24, 55, 0.16);
    place-content: center;
}

.backBtn {
    font-family: "PolySans Neutral";
    font-size: 14px;
    align-items: center;
    text-align: right;
    color: $main-color;
    text-transform: uppercase;
    display: flex;
}

.backBtn::before {
    content: "";
    background-image: url("../../../styles/assets/icons/left-arrow.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 8px;
    height: 12px;
    margin-right: 5px;
}

.hamburgerMenu::before {
    content: "";
    background-image: url("../../../styles/assets/icons/hamburger-menu.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 23px;
    height: 23px;
}

.blankSpace {
    width: 23px;
    height: 23px;
}

.logo {
    content: "";
    background-image: url("../../../styles/assets/new_ecocare_logo.webp");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 165px;
    height: 29px;
    cursor: pointer;
}

.progressBarHolder {
    width: 100%;
    height: 4px;
    display: flex;
    position: absolute;
    top: 74px;
}

.progressBarLeft {
    width: 50%;
    max-width: 840px;
    background-color: $main-color;
}

.progressBarRight {
    flex: 1;
    background-color: $main-color;
}

@media (min-width: 900px) {
    .navbarWrapper {
        height: 78px;
        padding: 16px 32px;
    }
}