.hamburger-icon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/hamburger-icon.svg) no-repeat;
    background-size: cover;
}

.icon-dashboard {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/dashboard.svg) no-repeat;

    &.active {
        background: url(./assets/icons/dashboard_active.svg) no-repeat;
    }
}

.icon-dashboard-master {
    display: block;
    width: 38px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        background: url(./assets/icons/dashboard.svg) no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 17px;
        height: 18px;
        margin: auto;
    }
}

.icon-myAppointments {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myAppointments.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myAppointments_active.svg) no-repeat;
    }
}

.icon-myOrders {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myOrders.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myOrders_active.svg) no-repeat;
    }
}

.icon-myAppointments {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myAppointments.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myAppointments_active.svg) no-repeat;
    }
}

.icon-myHealthProfile {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myHealthProfile.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myHealthProfile_active.svg) no-repeat;
    }
}

.icon-myReviews {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myReviews.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myReviews_active.svg) no-repeat;
    }
}

.icon-myWishlist {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myWishlist.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myWishlist_active.svg) no-repeat;
    }
}

.icon-chat {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/chat-icon-black.svg) no-repeat;

    &.active {
        background: url(./assets/icons/chat-icon-blue.svg) no-repeat;
    }
}

.icon-myAccount {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myAccount.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myAccount_active.svg) no-repeat;
    }
}

.icon-familyAndFriends {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/familyAndFriendsInactive.svg) no-repeat;

    &.active {
        background: url(./assets/icons/familyAndFriendsActive.svg) no-repeat;
    }
}

.icon-myQRCode {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/myQRCode.svg) no-repeat;

    &.active {
        background: url(./assets/icons/myQRCode.svg) no-repeat;
    }
}

.icon-settings {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/settings.svg) no-repeat;

    &.active {
        background: url(./assets/icons/settings_active.svg) no-repeat;
    }
}

.cart-icon {
    background: #fff;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 38px;
    width: fit-content;
    margin: 0px 5px;
    padding: 0px 10px;
    font-style: normal;
    font-size: 13px;
    color: #000;

    &::before {
        content: "";
        background: url(./assets/icons/cart_icon.svg) no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 19px;
        height: 19px;
        margin: auto;
    }
}

.cart-icon-simple {
    background: url(./assets/icons/cart_icon.svg) no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 14px;
    height: 19px;
}

.user-icon {
    display: block;
    width: 38px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #dbdfea;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;

    &::after {
        content: "";
        background: url(./assets/icons/user.svg) no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 19px;
        height: 19px;
        margin: auto;
    }
}

.info-icon {
    display: block;
    width: 22px;
    height: 22px;
    background: url(./assets/icons/info.svg) no-repeat;
    background-size: cover;
}

.darkInfoIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/blackInfoIcon.svg) no-repeat;
    background-size: cover;
    cursor: pointer;
}

.biomarker-very-low {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/biomarker-very-low.svg) no-repeat;
    background-size: cover;
}

.biomarker-low {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/biomarker-low.svg) no-repeat;
    background-size: cover;
}

.biomarker-normal {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/biomarker-normal.svg) no-repeat;
    background-size: cover;
}

.biomarker-high {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/biomarker-high.svg) no-repeat;
    background-size: cover;
}

.biomarker-positive {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/biomarker-positive.svg) no-repeat;
    background-size: cover;
}

.optimal-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/optimal-range.svg) no-repeat;
    background-size: cover;
}

.high-normal-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/high-normal.svg) no-repeat;
    background-size: cover;
}

.grade-1-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/grade-1-range.svg) no-repeat;
    background-size: cover;
}

.grade-2-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/grade-2-range.svg) no-repeat;
    background-size: cover;
}

.grade-3-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/grade-3-range.svg) no-repeat;
    background-size: cover;
}

.critical-range {
    display: block;
    width: 38px;
    min-height: 38px;
    background: url(./assets/icons/critical-range.svg) no-repeat;
    background-size: cover;
}

.biomarker-default {
    width: 38px;
    height: 38px;
}

.black-arrow-right {
    display: block;
    width: 14px;
    height: 14px;
    background: url(./assets/icons/black-arrow-right.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
}

.left-back-arrow {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/arrow-left.svg) no-repeat;
    background-size: contain;
}

.downloadIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/download.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
}

.shareIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/download.svg) no-repeat;
    background-size: contain;
}

.insightsIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/insights.svg) no-repeat;
    background-size: contain;
}

.doctorRecommendationsIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/doctor-recommendations.svg) no-repeat;
    background-size: contain;
}

.doctorRecommendationsGreenIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/doctor-recommendations-green.svg) no-repeat;
    background-size: contain;
}

.documents {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/documents.svg) no-repeat;
    background-size: contain;
}

.sun {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/sun.svg) no-repeat;
    background-size: contain;
}

.checkmarkIcon {
    display: block;
    width: 16px;
    height: 16px;
    background: url(./assets/icons/checkmark.svg) no-repeat;
    background-size: contain;
}

.videoBlackIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/video.svg) no-repeat;
    background-size: contain;
}

.videoWhiteIcon {
    display: block;
    width: 54px;
    height: 54px;
    background: url(./assets/icons/cameraOn.svg) no-repeat;
    background-size: contain;
}

.videoOffIcon {
    display: block;
    width: 54px;
    height: 54px;
    background: url(./assets/icons/cameraOff.svg) no-repeat;
    background-size: contain;
}

.calendarAppointmentIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/calendar-appointment.svg) no-repeat;
    background-size: contain;
}

.appointmentTickedIcon {
    display: block;
    width: 90px;
    height: 90px;
    background: url(./assets/icons/appointment-ticked.svg) no-repeat;
    background-size: contain;
}

.micSmBlackIcon {
    display: block;
    width: 20px;
    height: 20px;
    background: url(./assets/icons/microphone-sm.svg) no-repeat;
    background-size: contain;
}

.speakersSmBlackIcon {
    display: block;
    width: 20px;
    height: 20px;
    background: url(./assets/icons/speakers-sm.svg) no-repeat;
    background-size: contain;
}

.cameraSmBlackIcon {
    display: block;
    width: 20px;
    height: 20px;
    background: url(./assets/icons/camera-sm.svg) no-repeat;
    background-size: contain;
}

.addDocumentsBlackIcon {
    display: block;
    width: 18px;
    height: 18px;
    background: url(./assets/icons/add-documents.svg) no-repeat;
    background-size: contain;
}

.micOnIcon {
    display: block;
    width: 54px;
    height: 54px;
    background: url(./assets/icons/microphoneOn.svg) no-repeat;
    background-size: contain;
}

.micOffIcon {
    display: block;
    width: 54px;
    height: 54px;
    background: url(./assets/icons/microphoneOff.svg) no-repeat;
    background-size: contain;
}

.soundOffIcon {
    display: block;
    width: 30px;
    height: 30px;
    background: url(./assets/icons/sound-off.svg) no-repeat;
    background-size: contain;
}

.consultationInstructions {
    display: block;
    width: 300px;
    height: 300px;
    background: url(./assets/icons/consultation-instructions.webp) no-repeat;
    background-size: contain;
}

.alertIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/alert.svg) no-repeat;
    background-size: contain;
}

.roundedPlusIcon {
    display: block;
    width: 15px;
    height: 15px;
    background: url(./assets/icons/rounded-plus.svg) no-repeat;
    background-size: contain;
}

.roundedPlusIconGray {
    display: block;
    width: 15px;
    height: 15px;
    background: url(./assets/icons/rounded-plus-gray.svg) no-repeat;
    background-size: contain;
}

.roundedPlusPBIcon {
    display: block;
    width: 20px;
    height: 20px;
    background: url(./assets/icons/rounded-plus-pb.svg) no-repeat;
    background-size: contain;
}

.endCallWhiteIcon {
    display: block;
    width: 54px;
    height: 54px;
    background: url(./assets/icons/endCall.svg) no-repeat;
    background-size: contain;
}

.chatWhiteIcon {
    display: block;
    width: 30px;
    height: 30px;
    background: url(./assets/icons/chat-icon.svg) no-repeat;
    background-size: contain;
}

.folderWhiteIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/folder-icon.svg) no-repeat;
    background-size: contain;
}

.greenTickIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/green-tick.svg) no-repeat;
    background-size: contain;
}

.arrowDownIcon {
    display: block;
    width: 10px;
    height: 8px;
    background: url(./assets/icons/arrow-down.png) no-repeat;
    background-size: contain;
}

.arrowDownIconGray {
    display: block;
    width: 20px;
    height: 20px;
    background: url(./assets/icons/chevron-down.svg) no-repeat;
    background-size: contain;
    transform: rotate(180deg);
}

.arrowRightIcon {
    display: block;
    width: 10px;
    height: 8px;
    background: url(./assets/icons/arrow-down.png) no-repeat;
    background-size: contain;
    transform: rotate(-90deg);
}

.arrowRightFilledIcon {
    display: block;
    width: 30px;
    height: 30px;
    background: url(./assets/icons/arrow-right-filled.svg) no-repeat;
    background-size: contain;
}

.add-icon {
    box-shadow: 0px 4px 15px rgba(16, 18, 30, 0.1);
    border: 1px solid #ffffff;
    background: #ffffff;
    border-radius: 50%;
    display: block;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        background: url(./assets/icons/plus-black.svg) no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 19px;
        height: 19px;
        margin: auto;
    }
}

.eye-icon {
    box-shadow: 0px 4px 15px rgba(16, 18, 30, 0.1);
    border: 1px solid #ffffff;
    background: #ffffff;
    border-radius: 50%;
    display: block;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: "";
        background: url(./assets/icons/eye.svg) no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 19px;
        height: 19px;
        margin: auto;
    }
}

.deleteItemIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/bin-icon.svg) no-repeat;
    background-size: contain;
}

.editItemIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/edit.svg) no-repeat;
    background-size: contain;
}

.cardIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/card-icon.svg) no-repeat;
    background-size: contain;
}

.xIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/x.svg) no-repeat;
    background-size: contain;
}

.locationCheckIcon {
    display: block;
    width: 14px;
    height: 14px;
    background: url(./assets/icons/location_check.svg) no-repeat;
    background-size: contain;
}

.eyeOn {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/eye-on.svg) no-repeat;
    background-size: contain;
}

.eyeOff {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/eye-off.svg) no-repeat;
}

.ecocareDashboardIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/dashboard-menu-icon.svg) no-repeat;
    background-size: contain;
}

.directionIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/direction.svg) no-repeat;
    background-size: contain;
}

.modalCloseBlackIcon {
    display: block;
    width: 9px;
    height: 9px;
    background: url(./assets/icons/modal-close.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
}

.airportIcon {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./assets/icons/Airport.svg) no-repeat;
    background-size: contain;
}

.pharmacyIcon {
    display: block;
    width: 50px;
    height: 50px;
    background: url(./assets/icons/pharmacyIcon.svg) no-repeat;
    background-size: contain;
}

.pharmacyGreyIcon {
    display: block;
    width: 60px;
    height: 70px;
    background: url(./assets/icons/pharmacy_grey.svg) no-repeat;
    background-size: contain;
}

.payPalIcon {
    width: 53px;
    height: 29px;
    background: url(./assets/icons/PayPal.webp) no-repeat;
    background-size: contain;
}

.familyAndFriends {
    width: 125px;
    height: 122px;
    background: url(./assets/icons/familyAndFriends.webp) no-repeat;
    background-size: contain;
}

.addedFamilyMemberSuccess {
    width: 238px;
    height: 161px;
    background: url(./assets/icons/addedMemberSuccess.webp) no-repeat;
    background-size: contain;
}

.accountAllSetEcocareLogo {
    width: 124px;
    height: 124px;
    background: url(./assets/icons/accountAllSetEcocareLogo.webp) no-repeat;
    background-size: contain;
}

.languageIcon {
    background-image: url("./assets/icons/globe.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 18px;
    height: 18px;
}

.folderIcon {
    background-image: url("./assets/icons/folder.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    min-width: 40px;
    min-height: 40px;
}

.sunIcon {
    background-image: url("./assets/icons/sun.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 23px;
    height: 23px;
}

.iconeLike {
    background-image: url("./assets/icons/iconeLike.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 23px;
    height: 23px;
}

.folderIcontwo {
    background-image: url("./assets/icons/folderIcontwo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 23px;
    height: 23px;
}

.grayTickIcon {
    background-image: url("./assets/icons/gray-tick.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 8px;
    height: 8px;
}

.grayDoubleTickIcon {
    background-image: url("./assets/icons/gray-double-tick.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.whiteDoubleTickIcon {
    background-image: url("./assets/icons/white-double-tick.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 12px;
    height: 12px;
    margin-left: 5px;
}

.freeShippingIcon {
    background: url("./assets/icons/free-shipping-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.deliveryDaysIcon {
    background: url("./assets/icons/delivery-days.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.returnDaysIcon {
    background: url("./assets/icons/return-days.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.saveTheWorldIcon {
    background: url("./assets/icons/save-the-world\ 1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.premiumIcon {
    background: url("./assets/icons/premium-badge\ 1.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.sslIcon {
    background: url("./assets/icons/ssl.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 25px;
    height: 25px;
}

.downloadIcon {
    background-image: url("./assets/icons/downloadIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.previewIcon {
    background-image: url("./assets/icons/previewIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.videoCallIcon {
    background: url("./assets/icons/VideoCallIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 30px;
    height: 30px;
}

.sentIcon {
    background: url("./assets/icons/sent.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 14px;
    height: 14px;
}

.icon-last-item {
    background: url("./assets/icons/right-arrow.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 22px;
    height: 12px;
    margin-left: 10px;
}

.videoOff {
    display: block;
    width: 20px;
    height: 20px;
    background: url("./assets/icons/videoOff.svg") no-repeat;
    background-size: contain;
}

.anamnesisIcon {
    display: block;
    width: 24px;
    height: 24px;
    background: url(./assets/icons/anamnesis-icon.svg) no-repeat;
    background-size: contain;
}