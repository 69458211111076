@import "~react-image-gallery/styles/css/image-gallery.css";
@import "./variables";
@import "./icons";
@import "./buttons";

body {
    margin: 0;
    font-family: "PolySans Neutral";
}

span,
a {
    font-family: "PolySans Neutral";
    font-style: normal;
}

a {
    text-decoration: none !important;
}

a:hover {
    color: $darker-blue-text;
    text-decoration: none;
}

input:disabled {
    background-color: transparent;
}

hr {
    border: 0;
    border-top: 1px solid #d8d8d8;
    margin: 0;
    height: 1px;
}

textarea::placeholder {
    /* Modern browsers */
    font-family: "PolySans Neutral";
    font-size: 14px;
}

textarea:disabled {
    &::placeholder {
        color: #8e8f96b7;
    }
}

.home-page-main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mainFooter {
    background-color: #0B1B5C;
    color: #1f1f1f;
    // border-top: 1px solid #a9abb4;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.mainFooter hr {
    background-color: #a9abb4;
    margin-bottom: 20px;
}

h1,
h2,
h3,
h4 {
    // color: $main-color;
    font-weight: 700;
    font-family: "PolySans Median", sans-serif;
}

h1 {
    line-height: 46px;
}

p {
    font-family: "PolySans Neutral", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
}

label {
    font-family: "PolySans Neutral", sans-serif;
}

.blured {
    pointer-events: none;
    user-select: none;
}

.backgroundOverlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $background-overlay;
    z-index: 3;
    cursor: pointer;
}

button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.roundedButton {
    border-radius: 100px;
    background-color: transparent;
    border: 2px solid #014989;
    color: #014989;
    width: 100px;
    height: 40px;
    margin-left: 10px;

    &:hover {
        background-color: #014989;
        color: #fff;
    }
}

.transparentRegularButton {
    border-radius: 10px;
    background-color: transparent;
    border: 2px solid $gray-border-select;
    color: $dark;
    width: 100px;
    height: 40px;
    margin-left: 10px;

    &:hover {
        background-color: $gray-border-select;
    }

    &:disabled {
        opacity: 0.6;
    }
}

input[type='radio'] {
    accent-color: #0B1B5C;
}

.ReactModal__Overlay {
    background-color: $background-overlay !important;
    border: none !important;
    z-index: 1201;
}

.image-gallery-thumbnails {
    display: flex;
    align-items: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    max-width: 360px;
    // max-width: 450px;
    // height: 420px;
    border-radius: 15px;
    object-fit: cover;
    max-height: none !important;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 96px;
    max-width: 96px;
    object-fit: cover;
    border-radius: 5px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid #2023598a;
    border-radius: 10px;
}

.productName {
    color: #151d3f;
}

.productItemName {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 46px;
    color: $dark-gray;
}

.productItemPrice {
    color: $second-color;
    font-weight: 600;
    font-size: 30px;
    font-family: "PolySans Neutral";
    font-style: normal;
    // line-height: 24px;
    color: #515d90;

    &.redAlert {
        color: #912424;
        text-decoration-line: line-through;
    }
}

@media (max-width: 768px) {
    .infoWrapper {
        margin-left: 20px;
    }
}

.fc {
    margin-top: 2rem;
}

.fc .fc-header-toolbar .fc-toolbar-title {
    font-size: 18px;
    font-weight: bold;
}

.fc-header-toolbar.fc-toolbar {
    flex-wrap: wrap;
    border-bottom: 2px solid #12183729;
    padding-bottom: 5px !important;
    margin-bottom: 32px !important;
    display: none;
}

.fc-theme-standard .fc-list {
    border: 0px !important;
}

.fc-list-table tbody {
    display: flex;
    flex-wrap: wrap;
}

.fc-list-table tbody tr {
    display: block;
}

.fc .fc-list-table td {
    padding: 0 !important;
}

.fc-list-table tbody tr.fc-list-day {
    display: none;
}

.fc-list-table tbody tr.fc-list-event {
    width: 100%;
    background: transparent;
}

.fc-list-table tbody tr.fc-list-event:hover {
    background: transparent;
}

.fc-list-table tbody tr.fc-list-event:hover td {
    background: transparent;
}

.fc-list-table tbody tr.fc-list-event td.fc-list-event-time {
    display: none;
}

.fc-list-table tbody tr.fc-list-event td.fc-list-event-graphic {
    display: none;
}

.fc-list-table tbody tr.fc-list-event td.fc-list-event-title {
    display: block;
    border: 0;
}

.fc .fc-toolbar-chunk .fc-button-primary {
    background-color: transparent;
    border: 0;
}

.fc .fc-toolbar-chunk .fc-button-primary:hover {
    background-color: transparent;
    border: 0;
}

.fc .fc-toolbar-chunk .fc-button-primary:not(:disabled):active {
    background-color: transparent;
    border: 0;
}

.fc .fc-toolbar-chunk .fc-button-primary:focus {
    box-shadow: none;
}

.fc .fc-toolbar-chunk .fc-button-primary:disabled {
    background-color: transparent;
}

.fc .fc-toolbar-chunk .fc-button .fc-icon {
    color: $main-color;
    font-size: 1.9em;
}

.fc .fc-list-empty {
    background: transparent !important;
}

.event-accordion .event-accordion__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px 16px;
    margin-bottom: 25px;
    background: $white;
    border-radius: 6px;
    border: 2px solid #dadada;
    cursor: pointer;
}

.event-accordion .event-accordion__inner {
    display: none;
}

.event-accordion.open .event-accordion__inner {
    display: block;
}

.event-accordion .event-accordion__header .header-text {
    font-size: 18px;
    font-weight: bold;
}

.event-accordion .event-accordion__header .header-icon {
    color: $main-color;
    font-size: 18px;
    width: 15px;
    position: relative;
}

.event-accordion .event-accordion__header .header-icon i {
    position: absolute;
    left: 0;
    top: 4px;
}

.event-accordion .event-accordion__header .header-icon .fa-chevron-up {
    display: none;
}

.event-accordion.open .event-accordion__header .header-icon .fa-chevron-down {
    display: none;
}

.event-accordion.open .event-accordion__header .header-icon .fa-chevron-up {
    display: block;
}

.event-accordion .event-accordion__day {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #12183729;
}

.event-accordion .event-accordion__day .day__header {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 13px;
}

.event-accordion .event-accordion__day .day__events {
    display: flex;
    flex-wrap: wrap;
}

.event-accordion .event-accordion__day .day__events .ec-event {
    border: 2px solid $main-color;
    border-radius: 100px;
    color: $main-color;
    font-size: 18px;
    padding: 9px 0px;
    margin: 5px;
    width: 90px;
    cursor: pointer;

    &:focus {
        background: $main-color;
        color: $white;
    }
}

.event-accordion .event-accordion__day .day__events .ec-event:hover {
    background: $main-color;
    color: $white;
}

.event-accordion .event-accordion__day .day__events .ec-event.ec-event--disabled {
    border: 0;
    color: #9498ab;
    font-size: 18px;
    padding: 0;
    background: transparent;
    cursor: not-allowed;
}

.event-accordion .event-accordion__day .day__events .ec-event.ec-event--disabled:hover {
    background: transparent;
    color: #9498ab;
}

.available-info-window {
    padding: 30px 20px;
}

.available-info-window .available-info-window__header {
    text-transform: none;
    font-size: 14px;

    &.center {
        text-align: center !important;
    }

    &.uppercase {
        text-transform: uppercase;
    }
}

.available-info-window .available-info-window__divider {
    height: 1px;
    margin: 20px 0;
    background: #12183729;
}

.available-info-window__info {
    font-size: 18px;
    line-height: 27px;
}

.available-info-window__info b {
    margin-bottom: 4px;
}

.gm-style-iw-d {
    overflow: auto !important;

    button {
        display: none !important;
    }

    .chooseLocationBtn {
        width: 150px;
        display: flex !important;
        margin: 0 auto;
    }
}

.gm-ui-hover-effect {
    display: none !important;
}

.gm-style .gm-style-iw-c {
    padding: 0px;
    min-width: 300px !important;
}

.calendar-wrapper {
    &.hidden {
        display: none;
    }
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
    flex-direction: column;

    &.hidden {
        display: none;
    }
}

.loader {
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #202359;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 0.5s linear infinite;
    /* Safari */
    animation: spin 0.5s linear infinite;
}

.loadingText {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    color: $main-color;
    cursor: pointer;
    align-items: center;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.off-screen {
    display: none !important;
}

.litepicker-wrap {
    margin-bottom: 32px;
}

.litepicker-wrap .litepicker {
    width: 100%;
    // display: none !important;
}

.litepicker-wrap .litepicker.visible {
    display: block !important;
}

.litepicker-wrap .litepicker {
    font-family: inherit;
}

.litepicker-wrap .litepicker .container__months {
    background: #ffffff;
    border: 1px solid #d8d7d7;
    border-radius: 10px;
    box-sizing: border-box;
    width: 100%;
}

.litepicker-wrap .litepicker .container__months .month-item {
    border-radius: 4px;
    padding: 20px 22px;
    box-sizing: border-box;
    max-width: 450px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.container__months {
    min-width: 352px;
}

.litepicker-wrap .litepicker .container__months .month-item-header {
    font-size: 16px;
    font-weight: bold;
}

.litepicker-wrap .litepicker .container__months .month-item-weekdays-row {
    font-size: 14px;
}

.litepicker-wrap .litepicker .container__days {
    font-size: 14px;
    font-weight: bold;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    // gap: 10px;
}

// .litepicker-wrap .litepicker .container__months .month-item-header .button-previous-month,
// .litepicker .container__months .month-item-header .button-next-month {
//   display: none;
// }

.litepicker .container__days .day-item {
    cursor: auto;
    margin: 4px auto;
    width: 45px;
    height: 45px;
    color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    &:hover {
        color: #dddddd;
        -webkit-box-shadow: none;
    }
}

.litepicker .container__days .day-item.is-today {
    color: #dddddd;
}

.litepicker-wrap .litepicker .container__days .day-item.is-highlighted {
    pointer-events: auto;
    background-color: transparent;
    border-radius: 50%;
    border-color: #202359 !important;
    color: #202359;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        border: 1px solid #202359;
    }
}

.litepicker-wrap .litepicker .container__days .day-item.is-end-date {
    background-color: #202359;
    color: $white;
}

.errorMessage {
    color: $error;
    margin-top: 15px;
    font-size: 14px;
}

.bg-light {
    background-color: $light-background;
}

.shopping-cart-right-popup {
    position: absolute;
    right: 0;
    top: 78px;
    width: 400px;
    background-color: #fff;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    overflow: hidden;
    color: $main-color;

    .popup-header {
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .popup-bottom-buttons {
        min-width: 330px;
        position: absolute;
        bottom: 5%;
        left: 8%;

        button {
            width: 100%;
        }

        button:last-child {
            margin-top: 10px;
            background-color: $white;
            color: $second-color;
            border: 2px solid $main-color;
        }

        button:last-child:hover {
            background-color: $second-color;
            border-color: $second-color;
            color: $white;
        }
    }

    .cart-items {
        height: 50vh;
        overflow: scroll;
    }
}

.no-scroll {
    overflow: hidden;
}

@media screen and (max-width: 760px) {
    .mainFooter {
        border: none;
    }

    .image-gallery-content {
        margin: auto;
    }

    .image-gallery-bullets .image-gallery-bullet {
        background: #dcdcdc;
        box-shadow: 0 0.125rem 0.25rem RGB(0 0 0 / 8%) !important;
    }

    .image-gallery-bullets .image-gallery-bullet.active {
        transform: scale(1.2);
        border: 1px solid #dcdcdc;
        background: #014989;
        box-shadow: 0 0.125rem 0.25rem RGB(0 0 0 / 8%) !important;
    }

    ul {
        padding-left: 10px;
    }
}

@media screen and (max-width: 1000px) {
    .removeScroll {
        overflow: hidden;
    }
}

.mw-150 {
    min-width: 150px;
}

.w-100 {
    width: 100%;
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.justifyBetween {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.flex-grow-1 {
    flex-grow: 1;
}

.title {
    font-family: "Helvetica Neue";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    color: #2f3447;
}

.textAlignCenter {
    text-align: center;
}

.StripeElement {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: 20px;
}

.loadMoreCard {
    flex: 1;
    min-width: 280px;
    max-width: 280px;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px 20px 20px 0px;
    background: #ffffff;
    border: 1px solid #d7d7d7;
    border-radius: 4px;
    box-shadow: 0px 4px 15px rgba(16, 18, 30, 0.1);
}

/* Container breakpoints */
.container {
    max-width: 420px;
    width: 97%;
    margin-right: auto;
    margin-left: auto;
}

@media screen and (min-width: 640px) {
    .container {
        max-width: 630px;
    }
}

@media screen and (min-width: 960px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

.mr-1 {
    margin-right: 1rem;
}

.mr-2 {
    margin-right: 2rem;
}

.mr-3 {
    margin-right: 3rem;
}

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.ml-3 {
    margin-left: 3rem;
}

.mt-0 {
    margin-top: 0rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mb-3 {
    margin-bottom: 3rem;
}

.p-0 {
    padding: 0px;
}

.p-1 {
    padding: 1rem;
}

.p-2 {
    padding: 2rem;
}

.p-3 {
    padding: 3rem;
}

.px-1 {
    padding: 0px 10px;
}

.px-2 {
    padding: 0px 2rem;
}

.px-3 {
    padding: 0px 3rem;
}

.mx-1 {
    margin: 0px 10px;
}

.mx-2 {
    margin: 0px 2rem;
}

.mx-3 {
    margin: 0px 3rem;
}

.mx02 {
    margin: 0px 2px;
}

.my-1 {
    margin: 1rem 0px;
}

.my-2 {
    margin: 2rem 0px;
}

.my-3 {
    margin: 3rem 0px;
}

.bold {
    font-weight: 700 !important;
}

.search-input {
    padding: 0 10px;
    height: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e1e3eb;
    box-shadow: 0px 1px 2px #1a284d14;
    border-radius: 5px;
    // margin-right: 10px;
    align-items: center;
    display: flex;
    width: 100%;
    transition: all 0.5s;

    input {
        width: 100%;
        height: 100%;
        text-align: left;
        letter-spacing: 0px;
        color: #1a284d;
        opacity: 1;
        border: none !important;
        background: transparent;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }

    .arrow-icon {
        background-image: url("./assets/icons/arrow-left-balck.svg");
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        width: 18px;
        height: 18px;
        visibility: visible;
        margin-right: 15px;
    }

    &.hidden {
        width: 0%;
        border: 1px solid transparent;
        box-shadow: none;
        transition: all 0.5s;

        .arrow-icon {
            visibility: hidden;
            transition: visibility 0.8s;
        }

        input {
            width: 0%;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.text-area {
    padding: 10px;
    overflow: hidden;
    border-radius: 8px;
    margin: 5px;
    border-color: #cbcbcbe5;
    font-family: "PolySans Median";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
}

.divider {
    width: 100%;
    border-bottom: 1px solid #f1f1f1 !important;
}

.main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.light-blue-bg {
    background: #f9fdff;
}

.account-info {
    display: flex;
    align-items: center;
    background: #f5f6fa;
    width: 100%;
    padding: 20px 0px;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 3px;

    .primary {
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
    }

    .secondary {
        font-weight: 500;
        font-size: 12px;
        color: #8094ae;
        line-height: 18px;
    }
}

.sidebar-bottom-wrap {
    width: 97%;
    padding-left: 5px;
    padding-bottom: 15px;
}

.sign-out-sidebar {
    width: 92%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    padding-left: 20px;
    cursor: pointer;

    p {
        font-family: "PolySans Neutral";
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        text-align: right;
        color: #0b1b5c;
    }
}

.PhoneInputCountryIcon {
    width: 100% !important;
}

.PhoneInputCountryIcon--border {
    background-color: transparent !important;
    box-shadow: none !important;
}

.PhoneInput {
    border-radius: 5px !important;
    border: 1px solid #cbcbcb !important;
    padding: 10px !important;
    width: 100% !important;
}




.PhoneInputCountry {
    color: #11131f !important;
    font-size: 14px !important;
    margin-right: 13px !important;

    .PhoneInputCountrySelectArrow {
        margin-left: 10px !important;
        color: #131212 !important;
    }
}

.PhoneInputInput {
    line-height: 22px;
    font-size: 14px;
    color: #11131f;
    border: none;
    border-left: 1px solid #cbcbcb;
    padding: 5px 15px;

    &:focus {
        outline: none;
    }
}

.dashboard-layout {
    padding: 40px 0px;

    @media screen and (max-width: 760px) {
        padding: 0px 0px;
    }

    @media screen and (max-height: 800px) {
        padding: 20px 0px;
    }
}

.consultationRoomLayout {
    padding-top: 10px;

    @media screen and (max-width: 1420px) {
        padding-top: 5px !important;
    }

    @media (max-width: 760px) {
        padding-bottom: 0px !important;
        padding-top: 10px !important;
        height: calc(100vh - 65px);
        min-height: -webkit-fill-available;
    }
}

.checkBoxLink {
    cursor: pointer;
    color: #3343ab;
    margin: 0px 4px;

    &:hover {
        color: #0e1b5b;
    }
}

.home-care-btn {
    background: #f15e50;
    border-radius: 21px;
    padding: 10px 25px;
    color: #ffffff;
    font-size: 14px;
    margin: 0px 5px;
    text-align: center;
    height: fit-content;
}

.app-bar-right-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: end;

    @media (max-width: 900px) {
        width: auto;
    }
}

#cover-spin {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.547);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6b728f;
    font-size: 14px;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#cover-spin::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 42%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: #202359;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

// EcoCare Font Families
@font-face {
    font-family: "Suisse Works Regular Italic";
    src: local("SuisseWorks-RegularItalic"), url("./assets/fonts/SuisseWorks-RegularItalic.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Bulky";
    src: local("PolySans-Bulky"), url("./assets/fonts/PolySans-Bulky.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Bulky Italic";
    src: local("PolySans-BulkyItalic"), url("./assets/fonts/PolySans-BulkyItalic.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Median";
    src: local("PolySans-Median"), url("./assets/fonts/PolySans-Median.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Median Italic";
    src: local("PolySans-MedianItalic"), url("./assets/fonts/PolySans-MedianItalic.otf") format("truetype");
}

@font-face {
    font-family: "PolySans Neutral";
    src: local("PolySans-Neutral"), url("./assets/fonts/PolySans-Neutral.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Neutral Italic";
    src: local("PolySans-NeutralItalic"), url("./assets/fonts/PolySans-NeutralItalic.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans Slim";
    src: local("PolySans-Slim"), url("./assets/fonts/PolySans-Slim.otf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PolySans SlimItalic";
    src: local("PolySans-SlimItalic"), url("./assets/fonts/PolySans-SlimItalic.otf") format("truetype");
    font-display: swap;
}

.react-calendar {
    border: none !important;
    font-family: "PolySans Neutral" !important;
}

.react-calendar__month-view__weekdays__weekday {
    abbr {
        text-decoration: none !important;
        font-family: "PolySans Neutral" !important;
    }
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #7A7585 !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__month-view__days__day--weekend {
    color: inherit !important;
}

.react-calendar__tile--active {
    background: #202359 !important;
    color: #fff !important;
}