.cubeIcon {
    background-image: url("../../../styles/assets/icons/cube.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 24px;
    height: 24px;
}

.cubeContainer {
    width: fit-content;
    padding: 12px;
    background: #0b1b5c;
    border-radius: 10px;
    margin: 50px 0px 10px 10px;
}

.title {
    color: #11131f;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;

    @media (max-width: 768px) {
        color: var(--Primary-Dark, #11131f);
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
    }
}

.subTitle {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: 768px) {
        color: var(--Primary-Dark, #11131f);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
}

.confirmBarcode {
    color: #11131f;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin: 10px 0px 20px 0px;

    @media (max-width: 768px) {
        color: #11131f;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        margin: 30px 0px 0px 0px;
    }
}

.box {
    max-width: 600px;
    // width: 100%;
    margin: 0px auto;
    border-radius: 20px;
    background: #fff;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.06), -10px -10px 30px 0px rgba(0, 0, 0, 0.06);
    padding: 55px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;

    @media (max-width: 768px) {
        box-shadow: none;
    }

    p {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
    }

    .textBox {
        padding: 5px;
        border-radius: 8px;
        border: 1px solid #a9abb4;
        width: 85%;

        input {
            outline: none;
            width: 100%;
            border: none;
            background-color: #fff;
            color: #858fb0;
            font-size: 1rem;
        }
    }
}