.container {
    background-color: #f4f7fe;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
    justify-content: space-between;
}


.map {
    margin: 0px -20px;
}

.infoContainer {
    flex: 1;
    place-self: center;
}

.popUpContainer {
    margin-top: 3px;
    width: 100%;
}

.buttonsContainer {
    display: flex;
    place-content: center;
    margin-top: -15px;
    margin-bottom: 30px;
}

.daysHoursContainer {
    border-bottom: 1px solid #eaeaeb;
    padding-bottom: 15px;
    // margin: 0px -14px;
}

.descriptionContainer {
    // padding: 12px;
    // padding-bottom: 18px;
    max-width: 280px;
    // padding-left: 23px;
}

.merchantInfoContainer {
    display: flex;
    border-bottom: 1px solid #eaeaeb;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 15px 0px;
}

.bottomContainer {
    margin-top: 9px;
}

.directionContainer {
    display: flex;
    gap: 8px;
    cursor: pointer;
}

.directionLabel {
    padding-top: 5px;
}

.calendarContainer {
    background: "#F3F3F3";
    padding-top: 20px;
}

.calendarItemsContainer {
    display: flex;
    padding: 7px 0px;
}

.weekDayContainer {
    flex: 1;
    // padding-left: 17px;
}

.workingHoursContainer {
    flex: 1;
    // padding-right: 30px;
    text-align: right;
}

.viewMapContainer {
    margin: 35px 0px 10px 0px;
}

.imgShadow {
    background: #ffffff;
    border: 1px solid #DBDFEA;
    border-radius: 22px;
    padding: 10px;
    width: 100px;
    height: 100px;
    align-self: center;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    display: flex;

    img {
        text-align: center;
        max-width: 80px;
    }
}

.title {
    color: #11131F;
    padding-bottom: 7px;
    font-size: 30px;
    font-weight: 600;
}

.subtitle {
    color: #11131F;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.backIcon {
    background-image: url("../../../styles/assets/icons/backIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 35px;
    height: 35px;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
}

.directionIcon {
    background-image: url("../../../styles/assets/icons/directionIcon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 30px;
    height: 30px;
    cursor: pointer;
    top: 10px;
    left: 10px;
}

.buttons {
    margin-right: 10px;
    border-radius: 5px;
}

.modalTitle {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2f3447;
    padding-bottom: 9px;
    // padding-left: 5px;
}

.modalSubtitle {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #2f3447;
    // padding-left: 4px;
}

.weekDay {
    font-style: normal;
    font-size: 15px;
    line-height: 14px;
    color: #2f3447;
}

.workingHours {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    padding-left: 10px;
}

.email {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2f3447;
    margin-bottom: 20px;
}

.emailContainer {
    border-bottom: 1px solid #f1f1f1;
    // margin: 0px -37px;
    // padding-left: 26px;
}

.emailTitle,
.phone {
    font-weight: 600;
    padding-bottom: 5px;
    font-size: 16px;
}

.phone {
    padding-top: 10px;
}

.informationText {
    color: white;
    margin-top: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.number {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #2f3447;
}

.workingHoursTitle {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2f3447;
    // padding-left: 10px;
    margin-bottom: 10px;
    padding-top: 15px;
}

@media (max-width: 768px) {
    .imgShadow {
        display: none;
    }

    .map {
        margin: 0px -20px;
    }

    .buttonsContainer {
        margin-top: 15px;
    }

    .workingHoursContainer {
        padding-right: 10px;
    }

    .container {
        gap: 0px;
    }

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        padding-bottom: 0px
    }

    .subtitle {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #11131f;
    }
}

.infoTitle {
    padding-left: 15px;
    padding-bottom: 32px;
    font-weight: 600;
    font-size: 25px;
}