@import "../../styles/variables";

.primaryMenu {
    // color: #afb2c3;
}

.selectedMenu {
    color: #1f1f1f;
}

.primaryMenu,
.selectedMenu {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
}

.selectedMenuSection {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 9;
    padding: 20px 0;
}

.menus {
    flex-grow: 10;
    display: flex;
}

.blankSection {
    display: none;
}

.primaryMenu:first-child {
    margin-right: 1rem;
}

.selectedMenu {
    margin-left: 1rem;
    margin-right: 1rem;
}

.mainContent {
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-top: 1px solid #f1f1f1;
}

.leftSidebar {
    flex: 3;
}

.productsContainer {
    flex: 11;
}

.products {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.product {
    width: 100%;
    position: relative;
}

.zeroPoductsContainer {
    min-height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoriesBlueTitle {
    font-family: "PolySans Neutral";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: $main-color;
}

.title {
    color: #11131f;
    font-family: "PolySans Neutral";
    font-size: 22px;
    font-weight: 600;
    margin-left: 15px;
}

@media screen and (min-width: 640px) {
    .product {
        width: 226px;
        margin-right: 14px;
    }

    .products {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .mainContent {
        border: none;
    }
}

@media screen and (min-width: 960px) {
    .mainContent {
        flex-direction: row;
    }

    .blankSection {
        display: inline;
        min-width: 180px;
    }

    .products {
        margin-left: 20px;
    }
}

@media screen and (min-width: 1080px) {
    .products {
        justify-content: flex-start;
        margin-left: 1rem;
    }

    .blankSection {
        min-width: 230px;
    }
}

.discountBanner {
    width: 100%;
}